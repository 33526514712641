import { Breakpoint, DeviceScreenType } from '../types/breakpoint';
import { enumValues } from './enum';
const reversedBreakpointValues = enumValues(Breakpoint).reverse();
export const getCurrentBreakpoint = () => {
    const screenWidth = window.innerWidth;
    return reversedBreakpointValues.find(breakpoint => screenWidth && screenWidth >= breakpoint);
};
export const getCurrentDeviceScreenType = () => {
    return getCurrentBreakpoint() >= Breakpoint.lg ? DeviceScreenType.Desktop : DeviceScreenType.Mobile;
};
